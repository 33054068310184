<template>
  <v-container>
      <div class="my-10" style="text-align: center;">
          <h2>General Terms of Service Escuela de Lectura </h2>
      </div>
      <p>1. OVERVIEW </p>
      <p>
          This Agreement sets forth the general terms and conditions of your use of the Site and the products and services purchased or accessed through this Site (individually and collectively, the “Services”). Whether you are simply browsing or using this Site or purchase Services, your use of this Site and your electronic acceptance of this Agreement signifies that you have read, understand, acknowledge and agree to be bound by this Agreement, along with the following policies, including Privacy Policy and the applicable product agreements, which are incorporated herein by reference: 
The terms “we”, “us” or “our” shall refer to Escuela de Lectura. The terms “you”, “your”, “User” or “customer” shall refer to any individual or entity who accepts this Agreement, has access to your account or uses the Services. Escuela de Lectura may, in its sole and absolute discretion, change or modify this Agreement, and any policies or agreements which are incorporated herein, at any time, and such changes or modifications shall be effective immediately upon posting to this Site. Your use of this Site or the Services after such changes or modifications have been made shall constitute your acceptance of this Agreement as last revised. If you do not agree to be bound by this Agreement as last revised, do not use (or continue to use) this Site or the Services. In addition, Escuela de Lectura may occasionally notify you of changes or modifications to this Agreement by email. It is therefore very important that you keep your shopper account (“Account”) information current. Escuela de Lectura assumes no liability or responsibility for your failure to receive an email notification if such failure results from an inaccurate email address. 
      </p>
      <p>2. Account Eligibility</p>
      <p>It is your responsibility to provide accurate, current, and complete information on the registration forms, including an email. If there is ever an abuse issue or we need to contact you, we will use the primary email address we have on file. Providing false contact information of any kind may result in the termination of your account. Failure to provide the information requested may result in your order being denied.
You agree to be fully responsible for all use of your account and for any actions that take place through your account. It is your responsibility to maintain the confidentiality of your password and other information related to the security of your account.
The Service and any data you provide to Escuela de Lectura is hosted in the United States (U.S.) unless otherwise provided. If you access the Service from outside of the U.S., you are voluntarily transferring information (potentially including personally-identifiable information) and content to the U.S. and you agreeing that our collection, use, storage and sharing of your information and content is subject to the laws of the U.S., and not necessarily of the jurisdiction in which you are located.
      </p>
      <p>3. Escuela de Lectura Content</p>
      <p>Except for User Content (as defined below), all content made available through the Services, including images made available through website builder tools provided by Escuela de lectura (the “Licensed Images”), designs, templates, text, graphics, images, video, information, software, audio and other files, and their selection and arrangement, and all software used to provide the Services (collectively with the Licensed Images, "Escuela de Lectura Content"), are the property of Escuela de Lectura or its licensors. No Escuela de Lectura Content may not be modified, copied, distributed, framed, reproduced, republished, downloaded, scraped, displayed, posted, transmitted, sold or exploited for any purpose in any form or by any means, in whole or in part, other than as expressly permitted in this Agreement. You may not, directly or indirectly, reverse engineer, decompile, disassemble, or otherwise attempt to derive source code or other trade secrets from any Escuela de Lectura Content.
      </p>
      <p>4. User Content</p>
      <p>
        Solely for purposes of providing the Services, you hereby grant to Escuela de Lectura a non-exclusive, royalty-free, worldwide right and license to: (i) use, reproduce, publicly perform, publicly display, modify, translate, excerpt (in whole or in part); and (ii) make archival or back-up copies of User Content and User Websites. Except for the rights expressly granted herein, Escuela de Lectura does not acquire any right, title or interest in or to the User Content, all of which shall remain solely with you.
      </p>
      <p>5. Billing and Payment Information</p>
        <ul>
        <li>a.	Autorenewal.
            <ul>
            <li>Unless otherwise provided, you agree that until and unless you notify Escuela de Lectura of your desire to cancel the Services, you will be billed on an automatically recurring basis to prevent any disruption to your Services, using your credit card or other billing information on file with us.</li>
            </ul>
        </li>
        </ul>
        <ul>
        <li>b.	Taxes
            <ul>
            <li>Listed fees for the Services do not include any applicable sales, use, revenue, excise or other taxes imposed by any taxing authority. Any applicable taxes will be added to Escuela de Lectura is. invoice as a separate charge to be paid by you. ll fees are non-refundable when paid unless otherwise stated.</li>
            </ul>
        </li>
        </ul>
        <ul>
        <li>c.	Fraud
            <ul>
            <li>It is a violation of this Agreement for you to misuse or fraudulently use credit cards, charge cards, electronic funds transfers, electronic checks, or any other payment method. Escuela de Lectura may report any such misuse or fraudulent use, as determined in Escuela de Lectura es sole discretion, to governmental and law enforcement authorities, credit reporting services, financial institutions and/or credit card companies.</li>
            </ul>
        </li>
        </ul>
        <ul>
        <li>d.	Invoice Disputes.
            <ul>
            <li>You have ninety (30) days to dispute any charge or payment processed by Escuela de Lectura. If you have any questions concerning a charge on your account, please reach out to our billing department for assistance.</li>
            </ul>
        </li>
        </ul>
        <p>6. Cancellations and Refunds.</p>
      <ul>
        <li>a.	Money-Back Guarantee.
            <ul>
            <li>If an account is purchased and then cancelled within the thirty (30) days of the beginning of the term (the "Money-Back Guarantee Period"), you will, upon your written request to the Escuela de Lectura Support Team (the "Refund Request") within ninety (90) days of such termination or cancellation ("Notice Period"), receive a full refund of all; provided that such Money-Back Guarantee Refund shall be due to you only upon your compliance with, and subject in all respects to the terms and conditions of, this Section. Requests for these refunds must be made in writing to the Escuela de Lectura Support Team. Back Guarantee Refunds will not accrue, and shall not be paid under any circumstances, if you do not provide the applicable Refund Request within the Notice Period. The refund will proceed only if I do not use the service paid to Escuela de Lectura.</li>
            </ul>
        </li>
        </ul>
        <ul>
        <li>b.	Refund Eligibility.
            <ul>
            <li>Only first-time accounts are eligible for a refund. For example, if you've had an account with us before, canceled and signed up again, or if you have opened a second account with us, you will not be eligible for a refund. Violations of this Agreement will waive your rights under the refund policy.</li>
            </ul>
        </li>
        </ul>
        <ul>
        <li>c.	Foreign Currencies
            <ul>
            <li>Exchange rate fluctuations for international payments are constant and unavoidable. All refunds are processed in U.S. dollars and will reflect the exchange rate in effect on the date of the refund. All refunds are subject to this fluctuation and Escuela de Lectura is not responsible for any change in exchange rates between the time of payment and the time of refund.</li>
            </ul>
        </li>
        </ul>
    </v-container>
</template>

<script>
export default {
    mounted(){
        location.hash = "#top";
    }
}
</script>

<style scoped>
    .terms{
        padding: 10px 0 15px 0 !important;
        display: block;
        font-size: 19px;
    }

</style>